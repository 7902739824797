var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"drawer",style:({
    transform: ("" + (!_vm.value ? 'translateX(485px)' : 'translateX(0)')),
    opacity: ("" + (!_vm.value ? '0' : '1'))
  })},[_c('i',{staticClass:"el-icon-close",on:{"click":function($event){return _vm.$emit('input', false)}}}),_c('div',{staticClass:"drawer-info _flex"},[_c('div',{staticClass:"drawer-info__img-box"},[_c('img',{attrs:{"src":_vm.detail.imgUrl}}),([1, 2].includes(_vm.detail.catagories))?_c('el-button',{attrs:{"type":"info"},on:{"click":function () {
            _vm.$store.commit('SET_TOWER_CODE', _vm.detail)
            this$1.$store.commit('SET_TOWER_DATE', '')
            _vm.$router.push({
              path: '/dashboard',
              query: {
                code: _vm.detail.code
              }
            })
          }}},[_vm._v("View dashboard")]):_vm._e()],1),_c('div',{staticClass:"info-right _font-weight__600"},[_c('div',{staticClass:"info-right__name _font-size__heading3"},[_vm._v(_vm._s(_vm.detail.name))]),_vm._l((_vm.keys),function(item){return _c('div',{key:item.label,staticClass:"info-right__item _font-size__sub__heading"},[_c('span',[_vm._v(_vm._s(item.label))]),_c('span',[_vm._v(_vm._s(item.formatter && _vm.detail[item.key] ? item.formatter(_vm.detail[item.key]) : _vm.detail[item.key]))])])}),_c('div',{staticClass:"info-right__item _flex"},[_c('span',[_vm._v("Categories")]),_c('div',{staticClass:"item-catagories _font-weight__600"},[([0, 2].includes(_vm.detail.catagories))?_c('div',{staticClass:"_flex _flex-align__center"},[_c('img',{attrs:{"src":_vm.Inspected,"alt":""}}),_c('span',[_vm._v("Inspection")])]):_vm._e(),([1, 2].includes(_vm.detail.catagories))?_c('div',{staticClass:"_flex _flex-align__center"},[_c('img',{attrs:{"src":_vm.Monitoring,"alt":""}}),_c('span',[_vm._v("Sensor Monitoring")])]):_vm._e()])])],2)]),_c('div',{staticClass:"drawer-label _font-size__heading3 _font-weight__600"},[_vm._v("Inspection Report")]),_c('div',{staticClass:"drawer-table"},[_vm._m(0),_c('el-scrollbar',{staticClass:"drawer-table__scrollbar",style:({ height: _vm.tableHeight + 'px' })},[(_vm.isLoading)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticStyle:{"margin-top":"30px"}}):_vm._l((_vm.dateList),function(item,index){return _c('div',{key:index,staticClass:"drawer-table__row _flex _flex-align__center _flex-justify__between _font-size__sub__heading"},[_c('div',[_vm._v(" "+_vm._s(_vm.parseTime(item, 'reg'))+" ")]),_c('el-button',{attrs:{"type":"info"},on:{"click":function($event){return _vm.handleViewRecord(_vm.detail, item)}}},[_vm._v(" View record ")])],1)})],2)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"drawer-table__row _font-size__sub__heading _font-weight__600"},[_c('div',[_vm._v("Report Date")])])}]

export { render, staticRenderFns }